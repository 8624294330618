import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import Loader from "../../common/UI/Loader";
import ReactTable from "../../common/UI/ReactTable";
import SearchAndButtons from "../../common/UI/Search&Buttons";
import { defaultColumnFilter } from "../../common/UI/ReactTable/tableHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import eyeIcon from "../../assets/images/eye.svg";
import api from "../../api/api";
import { statusOptions } from "../../common/constants";
import fridgeIcon from "../../assets/images/fridge.svg";
import {
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from "../../utils/helpers";
import { toast } from "react-toastify";
import {toastSuccess, toastError } from "../../common/UI/Toasts";
import moment from "moment";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';

const OrderTable = (props) => {
  const {
    loading,
    data,
    seeAll,
    nextHandle,
    prevHandle,
    totalPage,
    currentPageNo,
    tableFor,
  } = props;

  const { pathname } = useLocation();

  const navigate = useNavigate();
  let columns = [
    {
      Header: "Order Number",
      accessor: "order_number",
      // Filter: defaultColumnFilter,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <span
              style={{
                color: setColor(row.original),
                fontSize: row.original?.message_notification ? 18 : 16,
              }}
            >{`${row.original?.order_number}`}</span>
          </>
        );
      },
    },
    {
      Header: "Customer Name",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: setColor(row.original),
              fontSize: row.original?.message_notification ? 18 : 16,
            }}
          >{`${row.original?.user?.first_name} ${row.original?.user?.last_name}`}</span>
        );
      },
    },
    {
      Header: "Product Name",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: setColor(row.original),
              fontSize: row.original?.message_notification ? 18 : 16,
            }}
          >
            {row.original.orderItem[0].product.name}
          </span>
        );
      },
    },
    {
      Header: "Total Price",
      accessor: "total",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: setColor(row.original),
              fontSize: row.original?.message_notification ? 18 : 16,
            }}
          >{`${row.original?.total?.toLocaleString("en", {
            useGrouping: false,
            minimumFractionDigits: 2,
          })}`}</span>
        );
      },
    },
    {
      Header: "Total Items",
      accessor: "total_items",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: setColor(row.original),
              fontSize: row.original?.message_notification ? 18 : 16,
            }}
          >{`${row.original?.total_items}`}</span>
        );
      },
    },
    {
      Header: "Delivery",
      accessor: "delivery.service",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: setColor(row.original),
              fontSize: row.original?.message_notification ? 18 : 16,
            }}
          >
            {`${row.original?.delivery?.title}`}
          </span>
        );
      },
    },
    {
      Header: "Date",
      accessor: "createdAt",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: setColor(row.original),
              fontSize: row.original?.message_notification ? 18 : 16,
            }}
          >{`${moment(row.original?.createdAt).format(
            "DD/MM/yyyy hh:mm A"
          )}`}</span>
        );
      },
    },
    {
      Header: "Status",
      accessor: "current_order_status",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: setColor(row.original),
              fontSize: row.original?.message_notification ? 18 : 16,
            }}
          >{`${
            statusOptions.find(
              (el) => el.value === row.original?.current_order_status
            )?.label || "-"
          }`}</span>
        );
      },
    },
    {
      Header: "Action",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>          
            <span
              onClick={() => {
                if (isUserAdmin() || isUserDoctor()) {
                  if (row.original?.message_notification) {
                    api.removeNotification({
                      id: row.original?._id,
                      message_notification: false,
                    });
                  }
                }
                // navigate(`/vieworder/${row.original._id}`, {
                //   state: {
                //     allOrdersPath: pathname,
                //   },
                // });
                window.open(`/vieworder/${row.original._id}`, '_blank');
              }}
            >
              <img src={eyeIcon} alt="view" width="24" height="24" />
            </span>
         </>
        );
      },
    },
  ];

  // as romil wanted to remove sub cat column and change to medicine name for dispatched section
  if (pathname == "/orders-dispatched") {
    columns = [
      ...columns.slice(0, columns.length - 4),
      {
        Header: "Product/Medication Name",
        disableSortBy: true,
        Cell: ({ row }) => {
          let order_items = row.original?.orderItem?.reduce(
            (prev, curr, index) =>
              curr?.product?.name
                ? prev +
                  `${
                    index == row.original?.orderItem.length - 1
                      ? `${curr?.product?.name}`
                      : `${curr?.product?.name},`
                  }`
                : prev,
            ""
          );
          return (
            <span
              style={{
                color: setColor(row.original),
                fontSize: row.original?.message_notification ? 18 : 16,
              }}
            >{`${order_items}`}</span>
          );
        },
      },
      ...columns.slice(columns.length - 4, columns.length),
    ];
  } else {
    columns = [
      ...columns.slice(0, columns.length - 4),
      {
        Header: "Med. Sub-category",
        disableSortBy: true,
        Cell: ({ row }) => {
          let subCatSet = new Set();

          row.original?.orderItem?.length &&
            row.original?.orderItem?.forEach((el) => {
              if (el.product.type === "medication") {
                subCatSet.add(el.product?.sub_category?.name);
              }
            });
          let str = "";
          subCatSet.forEach((el) => {
            if (!str) {
              str = el;
            } else {
              str = str + "\n" + el;
            }
          }, "");
          return (
            <span
              style={{
                color: setColor(row.original),
                fontSize: row.original?.message_notification ? 18 : 16,
              }}
            >{`${str}`}</span>
          );
        },
      },
      ...columns.slice(columns.length - 4, columns.length),
    ];
  }

  if (isUserAdmin()) {
    columns = [
      ...columns.slice(0, columns.length - 2),
      {
        Header: "Assigned Doctor",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <span
              style={{
                color: setColor(row.original),
                fontSize: row.original?.notification ? 18 : 16,
              }}
            >
              {row?.original?.doctor?.first_name &&
              row?.original?.doctor?.last_name
                ? `${row?.original?.doctor?.first_name} ${row?.original?.doctor?.last_name}`
                : "-"}
              {row.original.doctor_approval_notification &&
              row.original.doctor_approval_notification === "approved" ? (
                <FontAwesomeIcon icon={faCheckCircle} />
              ) : null}
            </span>
          );
        },
      },
      {
        Header: "Assigned Pharmacist",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <span
              style={{
                color: setColor(row.original),
                fontSize: row.original?.notification ? 18 : 16,
              }}
            >
              {row?.original?.pharmacist?.first_name &&
              row?.original?.pharmacist?.last_name
                ? `${row?.original?.pharmacist?.first_name} ${row?.original?.pharmacist?.last_name}`
                : "-"}
            </span>
          );
        },
      },
      ...columns.slice(columns.length - 2, columns.length),
    ];
  }

  const setColor = (rowData) => {
    if (isUserAdmin()) {
      if (tableFor === "All" || tableFor === "Dispatched") {
        return rowData?.message_notification
          ? "green"
          : (rowData?.doctor_approval_notification &&
              rowData?.doctor_approval_notification) === "rejected"
          ? "red"
          : rowData?.has_medicine
          ? "blue"
          : "black";
      } else if (
        tableFor === "Cancelled" ||
        tableFor === "PharmacistRejected"
      ) {
        return rowData?.has_medicine ? "blue" : "black";
      } else if (tableFor === "Pending") {
        return rowData?.message_notification
          ? "green"
          : (rowData?.doctor_approval_notification &&
              rowData?.doctor_approval_notification) === "rejected"
          ? "red"
          : rowData?.isFirstOrder == true
          ? "blue"
          : "black";
      }
    } else if (isUserDoctor()) {
      if (tableFor === "All") {
        return rowData?.message_notification ? "green" : "black";
      } else if (tableFor === "Pending") {
        return rowData?.message_notification
          ? "green"
          : rowData?.isPreApp == true && isUserDoctor()
          ? 'orange'
          : rowData?.isFirstOrder == true
          ? "blue"
          : "black";
      } else {
        return "black";
      }
    } else if (isUserPharmacist()) {
      return rowData?.isFirstOrder == true ? "blue" : "black";
    } else {
      return "black";
    }
  };

  const preAppOrder = (orderId) => {
    const payload = { orderId: orderId };
    api
      .preAppOrderUpdate(payload)
      .then((res) => {
        toastSuccess('Order updated successfully!!');
      })
      .catch((err) => {
        console.log('error', err);
      });
  }

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <ReactTable
          title="Orders"
          tableColumns={columns}
          tableData={data}
          seeAll={seeAll}
          nextHandle={nextHandle}
          prevHandle={prevHandle}
          totalPage={totalPage}
          pageNo={currentPageNo}
        />
      )}
    </>
  );
};

export default OrderTable;
