import moment from 'moment';

export const HeadersWithAuth = (uploadFile = false, loginToken = false) => {
  // return authorization header with jwt token
  let authToken = localStorage.getItem('authToken');
  let tempToken = localStorage.getItem('tempToken');
  
  if (authToken) {
    return {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': `${
        uploadFile ? 'multipart/form-data' : 'application/json'
      }`,
      Authorization: `Bearer ${authToken}`,
    };
  } else if(loginToken && tempToken) {
    return {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': `${
        uploadFile ? 'multipart/form-data' : 'application/json'
      }`,
      Authorization: `Bearer ${tempToken}`,
    };
  }
  else {
    return {};
  }
};

export const HeadersWithoutAuth = (uploadFile = false) => {
  return {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': `${
      uploadFile ? 'multipart/form-data' : 'application/json'
    }`,
  };
};

export const getFormDataFromObject = (obj, fileArray = []) => {
  const formData = new FormData();
  Object.keys(obj).forEach((el) => {
    if (el === 'address') {
      // append nested object
      for (let previewKey in obj[el]) {
        formData.append(`${el}[${previewKey}]`, obj[el][previewKey]);
      }
    } else {
      formData.append(el, obj[el]);
    }
  });
  return formData;
};

export const isUserAdmin = () =>
  ['admin'].includes(window.atob(localStorage.getItem('userTag')));

export const isUserDoctor = () =>
  ['doctor'].includes(window.atob(localStorage.getItem('userTag')));

export const isUserPharmacist = () =>
  ['pharmacist'].includes(window.atob(localStorage.getItem('userTag')));

export const isLoggedIn = () => {
  let userId = localStorage.getItem('userId');
  let authToken = localStorage.getItem('authToken');
  let userRole = localStorage.getItem('userTag');

  if (userId && authToken && userRole) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentUserRole = () =>
  window.atob(localStorage.getItem('userTag')) || null;

export const logout = () => {
  localStorage.removeItem('userTag');
  localStorage.removeItem('authToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('fullname');
  localStorage.removeItem('firstName');
  localStorage.removeItem('userphoto');
};

export const login = (
  authToken,
  userId,
  fullname,
  firstName,
  userphoto,
  userRole
) => {
  const encodeUserRole = window.btoa(userRole);
  localStorage.setItem('userTag', encodeUserRole);
  localStorage.setItem('authToken', authToken);
  localStorage.setItem('userId', userId);
  localStorage.setItem('fullname', fullname);
  localStorage.setItem('firstName', firstName);
  localStorage.setItem('userphoto', userphoto);
};

export const getErrorMessage = (type = null, name) => {
  switch (type) {
    case 'required':
      return `Please enter ${name?.toLowerCase()}`;
    case 'select-required':
      return `Please select ${name?.toLowerCase()}`;
    case 'maxDigitsAfterDecimal':
      return `${name} must have 2 digits after decimal or less`;
    case 'valid':
      return `Please Enter Valid ${name?.toLowerCase()}`;
    case 'confirm':
      return `Confirm ${name?.toLowerCase()} should be same as ${name?.toLowerCase()}`;
    case 'passstrength':
      return `${name} must be min 8 letters, with at least a symbol, upper and lower case letters and a number`;
    case 'phoneformat':
      return `Please Enter valid ${name?.toLowerCase()}`;
    case 'agreeterms':
      return `${name} is required`;
    default:
      return name;
  }
};

export const CountryByKey = {
  unitedkingdom: 'United Kingdom',
  malaysia: 'Malaysia',
};

export const RoleByKey = {
  admin: 'Admin',
  doctor: 'Doctor',
  pharmacist: 'Pharmacist',
};

export const GenderByKey = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
};

export const CouponTypeByKey = {
  general_promo_code: 'General Promo Code',
  brand_promo_code: 'Brand Promo Code',
  one_time_code: 'One Time Purchase Code',
};

export const convertDate = (date, format) =>
  moment.unix(date / 1000).format(format);

export const sortAscending = (data) => {
  return data.sort((a, b) =>
    a.name.toLowerCase() - b.name.toLowerCase()
      ? 1
      : b.name.toLowerCase() > a.name.toLowerCase()
      ? -1
      : 0
  );
};

export const getLabelValuePair = (
  array = [],
  label = 'name',
  value = '_id'
) => {
  let options = [];
  if (!array || !array.length) {
    return [];
  }
  options = array.map((el) => {
    return {
      label: el[label],
      value: el[value],
    };
  });
  return options;
};

export const sortObjectByKeys = (obj) => {
  let sortedObj = {};
  Object.keys(obj)
    .sort()
    .forEach((el) => {
      sortedObj[el] = obj[el];
    });
  return sortedObj;
};

export const compareObjects = (obj1, obj2) => {
  return (
    JSON.stringify(sortObjectByKeys(obj1)) ===
    JSON.stringify(sortObjectByKeys(obj2))
  );
};

export function imageDimentionValidator(height, width) {
  if (this.height !== height || this.width !== width) {
    return false;
  } else {
    return true;
  }
}

export function hasDecimalPlace(value, x) {
  let pointIndex = value.indexOf('.');
  return pointIndex >= 0 && pointIndex < value.length - x;
}

export const regex = (label) => {
  switch (label) {
    case 'name':
      return /^[a-zA-Z ]{3,30}$/;
    case 'email':
      return /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
    default:
      return null;
  }
};

export const validateFileExtensions = (
  fileObj,
  allowedExtensionsList = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG']
) => {
  let afterSpliting = fileObj?.name?.split('.');
  let choosenFileExtension = afterSpliting[afterSpliting.length - 1];
  if (allowedExtensionsList.includes(choosenFileExtension)) {
    return true;
  } else {
    return false;
  }
};
