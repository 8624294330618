import {
  faCommentSlash,
  faDownload,
  faEye,
  faInbox,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactTable from '../../../common/UI/ReactTable';
import { orderBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import eyeIcon from '../../../assets/images/eye.svg';
import CustomDropdown from '../../../common/UI/Dropdown';
import AddAndEditModal from '../../../common/UI/CustomModal/addAndEditModal';
import api from '../../../api/api';
import { orderActions, statusOptions } from '../../../common/constants';
import { useSelector } from 'react-redux';
import { getOrderById } from '../../../store/reducers/order';
import { useDispatch } from 'react-redux';
import {
  toastError,
  toastInformation,
  toastSuccess,
} from '../../../common/UI/Toasts';
import {
  getLabelValuePair,
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from '../../../utils/helpers';
import ReactTextareaAutosize from 'react-textarea-autosize';
import CustomInput from '../../../common/FormComponents/Input';
import { Button } from 'react-bootstrap';

const ProductDetailTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderData } = useSelector((state) => state.order);
  const { data } = props;
  const [changeToStatus, setChangeToStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangeButtonDisabled, setIsChangeButtonDisabled] = useState(false);
  //
  const [doctor, setDoctor] = useState(null);
  const [pharmacist, setPharmacist] = useState(null);
  const [captureAmount, setCaptureAmount] = useState();
  const [modifiedStatusOptions, setModifiedStatusOptions] =
    useState(statusOptions);
  const dispatch = useDispatch();
  const [delType, setDelType] = useState();

  //
  const pharmacistsAndDoctors = useSelector(
    (state) => state.order.pharmacistsAndDoctors,
  );

  useEffect(() => {
    if (data?.current_order_status) {
      let modifiedStatusOptionsClone = [...modifiedStatusOptions];
      let placedIndex = modifiedStatusOptionsClone.findIndex(
        (el) => el.value === 'placed',
      );
      // disable the placed option
      modifiedStatusOptionsClone[placedIndex] = {
        ...modifiedStatusOptionsClone[placedIndex],
        disabled: true,
      };
      if (data?.current_order_status === 'dispatched') {
        modifiedStatusOptionsClone = modifiedStatusOptionsClone.map((el) => {
          if (el.value === 'dispatched') return el;
          else return { ...el, disabled: true };
        });
      } else if (data?.current_order_status === 'rejected') {
        modifiedStatusOptionsClone = modifiedStatusOptionsClone.map((el) => {
          if (el.value === 'rejected') return el;
          else return { ...el, disabled: true };
        });
      } else if (data?.current_order_status === 'accepted') {
        modifiedStatusOptionsClone = modifiedStatusOptionsClone.map((el) => {
          if (el.value === 'rejected') return { ...el, disabled: true };
          else return el;
        });
      }
      setModifiedStatusOptions(modifiedStatusOptionsClone);
    }
  }, [data]);
  useEffect(() => {
    if (orderData) {
      let doctorFound = false;
      let pharmacistFound = false;
      if (orderData?.doctor_id) {
        // try to get the value from reducer
        if (pharmacistsAndDoctors) {
          const thisDoctor = pharmacistsAndDoctors.find(
            (el) => el._id === orderData?.doctor_id,
          );
          if (thisDoctor) {
            doctorFound = true;
            setDoctor({
              value: thisDoctor._id,
              label: `Dr. ${thisDoctor?.first_name} ${thisDoctor?.last_name}`,
            });
          } else {
            doctorFound = false;
          }
        }
        if (!pharmacistsAndDoctors || (pharmacistsAndDoctors && !doctorFound)) {
          // get doctor from api call
          api.getUserById(orderData?.doctor_id).then((res) => {
            const thisDoctor = res?.data?.data;
            setDoctor({
              value: thisDoctor._id,
              label: `Dr. ${thisDoctor?.first_name} ${thisDoctor?.last_name}`,
            });
          });
        }
      }
      if (orderData?.pharmacist_id) {
        // try to get the value from reducer
        if (pharmacistsAndDoctors) {
          const thisPharmacist = pharmacistsAndDoctors.find(
            (el) => el._id === orderData?.pharmacist_id,
          );
          if (thisPharmacist) {
            pharmacistFound = true;
            setPharmacist({
              value: thisPharmacist._id,
              label: `${thisPharmacist?.company_name}`,
            });
          } else {
            pharmacistFound = false;
          }
        }
        if (
          !pharmacistsAndDoctors ||
          (pharmacistsAndDoctors && !pharmacistFound)
        ) {
          // get doctor from api call
          api.getUserById(orderData?.pharmacist_id).then((res) => {
            const thisPharmacist = res?.data?.data;
            setPharmacist({
              value: thisPharmacist._id,
              label: `${thisPharmacist?.company_name}`,
            });
          });
        }
      }
    }
  }, [orderData]);
  const columns = [
    {
      Header: 'Name',
      accessor: 'product.name',
      disableSortBy: true,
      disableFilters: true,
      // Cell: ({row}) => {
      //   return <span>{row.orig</span>
      // }
    },
    {
      Header: 'Product Type',
      accessor: 'product.type',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Variant',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original?.product?.has_variant) {
          const variantAttributes = row.original.variant?.attributes;
          if (variantAttributes && Object.keys(variantAttributes)?.length) {
            return Object.keys(variantAttributes).map((el, index) => {
              return (
                <div key={index}>
                  <strong>{el}</strong> - {`${variantAttributes[el]}`}
                </div>
              );
            });
          }
          return <span>-</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      Header: () => {
        return isUserAdmin()
          ? `Total Price`
          : isUserPharmacist()
            ? `Unit Trade Price`
            : '';
      },
      accessor: 'after_promotion_discount',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (isUserAdmin()) {
          return (
            <div>
              {row.original?.after_promotion_discount?.toLocaleString('en', {
                useGrouping: false,
                minimumFractionDigits: 2,
              })}
            </div>
          );
        } else if (isUserPharmacist()) {
          return <div>{row.original?.variant?.trade_price}</div>;
        }
      },
    },
    {
      Header: 'Brand',
      accessor: 'product.brand.name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Qty',
      accessor: 'quantity',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'SKU',
      accessor: 'product.sku',
      disableSortBy: true,
      disableFilters: true,
    },

    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              navigate(`variant/${row.original.variant?._id}?itemId=${row.original?._id}`, {
                state: {
                  ...location.state,
                  viewOrderPath: location.pathname,
                },
              });
            }}
          >
            <img src={eyeIcon} alt="view" width="24" height="24" />
          </span>
        );
      },
    },
    {
      Header: 'Approval Status',
      Cell: ({ row }) =>
        row?.original?.product?.type === 'medication' ? (
          <p
            className="text-center order_status"
            style={{
              backgroundColor:
                row?.original?.answer_status === 'Approved' ||
                  row?.original?.answer_status === 'InReview'
                  ? '#f1faec'
                  : '#ffe7ed',
              color:
                row?.original?.answer_status === 'Approved' ||
                  row?.original?.answer_status === 'InReview'
                  ? '#73d13d'
                  : '#ff084b',
            }}
          >
            {row?.original?.answer_status}
          </p>
        ) : (
          <div className="text-center">-</div>
        ),
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const toggleModal = () => {
    setChangeToStatus(null);
    setIsModalOpen(!isModalOpen);
  };

  const handleAssignDoctorOrPharmacist = () => {
    const payload = {
      pharmacist_id: pharmacist?.value,
      doctor_id: doctor?.value,
    };
    if (payload.pharmacist_id === orderData?.pharmacist_id) {
      delete payload.pharmacist_id;
    }
    if (payload.doctor_id === orderData?.doctor_id) delete payload.doctor_id;

    if (!payload || (payload && !Object.keys(payload).length)) {
      toastInformation('No information changed!');
      return;
    }
    // when an order is rejected by pharmacist, remove that key when a new pharmacy is assigned to it
    if (orderData.rejected_by_pharmacist && payload.pharmacist_id) {
      payload.remove_rejected_by_pharmacist = true;
    }
    api
      .updateOrder(orderData?._id, payload)
      .then((res) => {
        toastSuccess('Order updated successfully!');
        dispatch(getOrderById(orderData?._id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isPharmacyDropdownDisable = () => {
    return orderData?.current_order_status === 'dispatched' ||
      orderData?.current_order_status === 'cancelled'
      ? true
      : orderData?.has_medicine
        ? !orderData?.doctor_id ||
        (orderData?.doctor_id &&
          (orderData?.orderItem.filter(
            (el) =>
              el?.product?.type === 'medication' &&
              el.answer_status === 'InReview',
          ).length ||
            orderData?.orderItem.length ===
            orderData?.orderItem.filter(
              (el) =>
                el?.product?.type === 'medication' &&
                el.answer_status === 'Rejected',
            ).length))
        : orderData?.current_order_status !== 'placed';
  };
  const isDoctorDropdownDisable = () => {
    return (
      orderData?.current_order_status !== 'placed' || orderData?.pharmacist_id
    );
  };
  //
  const handleCancelOrder = (flag = false) => {
    // Open model to cancel the order (On click of cancel button)
    if (!flag) {
      setIsModalOpen(true);
      setChangeToStatus({
        status: 'cancel',
        message: 'Are you sure you want to change the order status to Cancel?',
      });
    }
    // if modal is opened and order is cancelled (on click of modal's action button)
    else {
      if (changeToStatus?.reason) {
        setIsChangeButtonDisabled(true);
        api
          .putOrderStatus(orderData?._id, {
            status: 'cancelled',
            capture_amount: captureAmount,
            rejection_reason: changeToStatus.reason,
            payment_intend_id: orderData?.payment_intent_id,
          })
          .then((res) => {
            toastSuccess('Order Cancelled successfully!!');
            dispatch(getOrderById(orderData?._id));
            setIsChangeButtonDisabled(false);
            setIsModalOpen(false);
            setChangeToStatus(null);
          })
          .catch((err) => {
            setIsChangeButtonDisabled(false);
            toastError(err?.message, { autoClose: false });
          });
      } else {
        toastError(`Rejection Reason is manadatory.`);
      }
    }
  };


  const handleCancelOrderSub = (flag = false) => {
    // Open model to cancel the order (On click of cancel button)
    if (!flag) {
      setIsModalOpen(true);
      setChangeToStatus({
        status: 'cancelSub',
        message: 'Are you sure you want to change the order and Subscription status to Cancel?',
      });
    }
    // if modal is opened and order is cancelled (on click of modal's action button)
    else {
      if (changeToStatus?.reason) {
        setIsChangeButtonDisabled(true);
        api
          .putOrderStatus(orderData?._id, {
            status: 'cancelled',
            capture_amount: captureAmount,
            rejection_reason: changeToStatus.reason,
            payment_intend_id: orderData?.payment_intent_id,
            CancelWithSub: true
          })
          .then((res) => {
            toastSuccess('Order Cancelled successfully!!');
            dispatch(getOrderById(orderData?._id));
            setIsChangeButtonDisabled(false);
            setIsModalOpen(false);
            setChangeToStatus(null);
          })
          .catch((err) => {
            setIsChangeButtonDisabled(false);
            toastError(err?.message, { autoClose: false });
          });
      } else {
        toastError(`Rejection Reason is manadatory.`);
      }
    }
  };

  const handleCancelMDSOrder = (flag = false) => {
    if (!flag) {
      setIsModalOpen(true);
      setChangeToStatus({
        status: 'cancelMDS',
        message: 'Are you sure you want to change the order status to Cancel?',
      });
    }
    // if modal is opened and order is cancelled (on click of modal's action button)
    else {
      if (changeToStatus?.reason) {
        setIsChangeButtonDisabled(true);
        api
          .cancelMDSOrder(orderData?.FHOrderId, {
            cancellationReason: changeToStatus.reason,
          })
          .then((res) => {
            toastSuccess('Order Cancelled successfully!!');
            dispatch(getOrderById(orderData?._id));
            setIsChangeButtonDisabled(false);

            setIsModalOpen(false);
            setChangeToStatus(null);
          })
          .catch((err) => {
            setIsChangeButtonDisabled(false);
            toastError(err?.message, { autoClose: false });
          });
      }
    }
  };
  const handleDispatchOrder = (flag = false, delTypeObj) => {
    if (!flag) {
      setDelType(delTypeObj.type);
      if (
        delTypeObj.type === 'dhl' &&
        props?.countryObj?.country_code == null &&
        orderData?.address?.country_code == null
      ) {
        toastError('Please select the country!!');
        return;
      }
      if (
        delTypeObj.type === 'royal_mail' &&
        (!props.selectedWeightAndPostage.weight?.value ||
          !props.selectedWeightAndPostage.postage?.value)
      ) {
        toastError('Please select weight and postage!!');
        return;
      }
      setIsModalOpen(true);
      setChangeToStatus({
        status: 'dispatch',
        message:
          'Are you sure you want to change the order status to Dispatch?',
      });
    } else {
      setIsChangeButtonDisabled(true);
      api
        .putOrderStatus(orderData?._id, {
          status: 'dispatched',
          country: props?.countryObj?.value,
          country_code: props?.countryObj?.country_code,
          payment_intend_id: orderData?.payment_intent_id,
          amount: orderData?.total,
          weight: props.selectedWeightAndPostage?.weight?.value,
          packageFormatIdentifier:
            props.selectedWeightAndPostage?.postage?.value,
          deliveryServiceType: delType,
        })
        .then((res) => {
          toastSuccess('Order status changed successfully!!');
          dispatch(getOrderById(orderData?._id));
          setChangeToStatus(null);
          setIsChangeButtonDisabled(false);
          setIsModalOpen(false);
          //
          if (res.data?.data?.label) {
            // royal mail
            const linkSource = `data:application/pdf;base64,${res.data.data.label}`;
            const downloadLink = document.createElement('a');
            const fileName = 'DeliveryLabel.pdf';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          } else if (orderData.delivery?.service === 'quiver') {
            api.getDeliveryLabel(orderData?._id).then((res) => {
              // console.log(res.data.data.deliveryLabel);
              const url = window.URL.createObjectURL(
                new Blob(
                  [new Uint8Array(res?.data?.data?.deliveryLabel?.data).buffer],
                  {
                    type: 'application/pdf',
                  },
                ),
              );
              if (url) {
                let today = new Date();
                let dateTime = `${today.getFullYear()}_${today.getMonth() + 1
                  }_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `delivery${dateTime}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              }
            });
          } else {
            props.printDeliveryLabelForVIP();
          }
          if (orderData?.has_medicine) {
            orderData?.orderItem.forEach((el) => {
              if (el?.product?.type === 'medication') {
                props.printInformationLabel(el);
                // props.printDrugLabel({
                //   ...el,
                //   user: orderData?.user,
                //   createdAt: orderData?.createdAt,
                // });
              }
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
          setIsChangeButtonDisabled(false);
          toastError(err.message, { autoClose: false });
        });
    }
  };
  const handlePharmacyRejectOrder = (flag = false) => {
    if (!flag) {
      setIsModalOpen(true);
      setChangeToStatus({
        status: 'pharmacyReject',
        message: 'Are you sure you want to reject this order?',
      });
    } else {
      setIsChangeButtonDisabled(true);
      api
        .updateOrder(orderData?._id, { rejected_by_pharmacist: true })
        .then((res) => {
          toastSuccess('Order Rejected Successfully!!');
          navigate('/orders');
          setIsChangeButtonDisabled(false);
        })
        .catch((err) => {
          console.log('err', err);
          setIsChangeButtonDisabled(false);
          toastError(err.message, { autoClose: false });
        });
    }
  };

  return (
    <div>
      <div className="header_top order_header_main align-items-center">
        <h3>View Order</h3>
        {data?.has_medicine && data?.current_order_status === 'dispatched' ? (
          <Button
            className="ms-2 p-3 fw-bold"
            onClick={props.printPrescription}
          >
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Prescription
          </Button>
        ) : null}
        <div className="ms-auto order_id_main">
          <div>
            <p>
              Order Id:<a href="#">{data?.order_number}</a>
            </p>
          </div>
          <div>
            <p>Status</p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span
            className={`order_status `}
            style={{
              backgroundColor:
                orderData?.current_order_status === 'placed'
                  ? '#f1f3f6'
                  : orderData?.current_order_status === 'dispatched'
                    ? '#f1faec'
                    : orderData?.current_order_status === 'cancelled'
                      ? '#ffe7ed'
                      : 'white',
              color:
                orderData?.current_order_status === 'placed'
                  ? '#505459'
                  : orderData?.current_order_status === 'dispatched'
                    ? '#73d13d'
                    : orderData?.current_order_status === 'cancelled'
                      ? '#ff084b'
                      : 'black',
            }}
          >
            {orderData?.current_order_status === 'placed' &&
              orderData?.pharmacist_id
              ? 'assigned'
              : orderData?.current_order_status}
          </span>
        </div>
        <div className="mx-1">
          {isUserAdmin() ? (
            <>
              <Button
                className="order_btns m-1"
                variant="danger"
                onClick={() => {
                  handleCancelOrder();
                }}
                disabled={
                  orderData?.current_order_status === 'cancelled' ||
                  orderData?.current_order_status === 'dispatched'
                }
              >
                Cancel Order
              </Button>
              <Button
                className="order_btns m-1"
                variant="danger"
                onClick={() => {
                  handleCancelOrderSub();
                }}
                disabled={
                  orderData?.current_order_status === 'cancelled' ||
                  orderData?.current_order_status === 'dispatched'
                }
              >
                Cancel Order & Sub
              </Button>
            </>
          ) : isUserPharmacist() ? (
            <div className="d-flex">
              <Button
                variant="success"
                className=" order_btns mx-1"
                onClick={() => {
                  setDelType('royal_mail');
                  handleDispatchOrder(false, { type: 'royal_mail' });
                }}
                disabled={orderData?.current_order_status === 'dispatched'}
              >
                Dispatch Royal Mail
              </Button>
              <Button
                variant="success"
                className=" order_btns mx-1"
                onClick={() => {
                  setDelType('dhl');
                  handleDispatchOrder(false, { type: 'dhl' });
                }}
                disabled={orderData?.current_order_status === 'dispatched'}
              >
                Dispatch DHL
              </Button>
              <Button
                variant="danger"
                className=" order_btns mx-1"
                onClick={() => {
                  handlePharmacyRejectOrder();
                }}
                disabled={orderData?.current_order_status !== 'placed'}
              >
                Reject
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {isModalOpen ? (
        <AddAndEditModal
          action="Change"
          module="Status"
          handleToggle={toggleModal}
          handleAction={() => {
            changeToStatus.status === 'cancel'
              ? handleCancelOrder(true)
              : changeToStatus.status === 'dispatch'
                ? handleDispatchOrder(true)
                : changeToStatus.status === 'pharmacyReject'
                  ? handlePharmacyRejectOrder(true)
                  : changeToStatus.status === 'cancelSub'
                    ? handleCancelOrderSub(true)
                    : null;
          }}
          show={isModalOpen}
          disabled={isChangeButtonDisabled}
        >
          <span className="mb-4">{changeToStatus?.message}</span>
          {changeToStatus?.status === 'cancel' ||
            changeToStatus?.status === 'cancelSub' ? (
            <>
              <ReactTextareaAutosize
                name="reason"
                type="text"
                className="mt-3 form-control form_input"
                placeholder=""
                onChange={(e) => {
                  setChangeToStatus({
                    ...changeToStatus,
                    reason: e.target.value,
                  });
                }}
                value={changeToStatus?.reason}
                minRows={3}
              />
              <span className="mb-4">
                Capture Amount - enter amount to capture whilst cancelling
                order. Leave blank to refund whole order amount.
              </span>
              <input
                className="form-control me-2  border-1 shadow-none"
                type="search"
                placeholder="Enter Amount"
                aria-label="Search"
                onChange={(e) => setCaptureAmount(e.target.value)}
              />
            </>
          ) : null}
        </AddAndEditModal>
      ) : null}
      <ReactTable
        title="Order Products"
        tableColumns={columns}
        tableData={props.data?.orderItem || []}
        seeAll={true}
      />
      {isUserAdmin() ? (
        <div className="d-flex">
          {' '}
          {orderData?.has_medicine ? (
            <div className="d-flex">
              <div className="order_id_main">
                <div>
                  <p>Doctor</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <CustomDropdown
                  isDisabled={isDoctorDropdownDisable()}
                  className=""
                  options={
                    pharmacistsAndDoctors &&
                    pharmacistsAndDoctors
                      .filter((el) => el.role === 'doctor' && el.status)
                      .map((el) => {
                        return {
                          label: `Dr. ${el?.first_name} ${el?.last_name}`,
                          value: el?._id,
                        };
                      })
                  }
                  height={'40px'}
                  placeholder={'Select'}
                  placeholderColor={'#9aa5ad'}
                  border={'1 px solid #9aa5ad'}
                  containerWidth={'175px'}
                  onChange={(value) => {
                    setDoctor(value);
                  }}
                  value={doctor}
                />
              </div>
            </div>
          ) : null}
          <div className="order_id_main">
            <div>
              <p>Pharmacy</p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <CustomDropdown
              isDisabled={isPharmacyDropdownDisable()}
              className=""
              options={
                pharmacistsAndDoctors &&
                pharmacistsAndDoctors
                  .filter((el) => el.role === 'pharmacist' && el.status)
                  .map((el) => {
                    return {
                      label: ` ${el?.company_name}`,
                      value: el?._id,
                    };
                  })
              }
              value={pharmacist}
              height={'40px'}
              placeholder={'Select'}
              placeholderColor={'#9aa5ad'}
              border={'1 px solid #9aa5ad'}
              containerWidth={'175px'}
              onChange={(value) => {
                setPharmacist(value);
              }}
            />
          </div>
          <button
            disabled={isPharmacyDropdownDisable() && isDoctorDropdownDisable()}
            type="button"
            className="btn btn-primary btn-sm order_btns"
            style={{ marginLeft: '20px' }}
            onClick={handleAssignDoctorOrPharmacist}
          >
            Assign
          </button>
        </div>
      ) : null}
      {orderData?.delivery?.delivery_note ? (
        <div>
          <span className="fs-18 fw-bold">Delivery Note:</span>{' '}
          <span className="fs-16">{orderData?.delivery.delivery_note}</span>
        </div>
      ) : null}
      {orderData?.rejection_reason ? (
        <div>
          <span className="fs-18 fw-bold ">Rejection Reason:</span>{' '}
          <span className="fs-16 text-danger">
            {orderData?.rejection_reason}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailTable;
