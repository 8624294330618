import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import api from '../../../api/api';
import CustomDropdown from '../../../common/UI/Dropdown';
import { toastSuccess } from '../../../common/UI/Toasts';
import { getOrderById } from '../../../store/reducers/order';
import {
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from '../../../utils/helpers';
import UpdateAddressModal from './updateAddressModal';
import { useNavigate } from 'react-router-dom';

const UpdateOrderDetails = (props) => {
  const navigate = useNavigate();
  
  const { orderData } = props;
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const [addressModalShow, setAddressModalShow] = useState(false);
  const [variantSectionShow, setVariantSectionShow] = useState(false);
  const [variantOptions, setVariantOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [newProductsOptions, setNewProductsOptions] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState();
  const [selectedOrderItem, setSelectedOrderItem] = useState();
  const [addOrderItemForm, setAddOrderItemForm] = useState(false);
  const [selectedNewProduct, setSelectedNewProduct] = useState();
  const [orderItemQuantity, setOrderItemQuantity] = useState({ label: '1', value: '1' });


  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (isUserDoctor()) {
      setVariantSectionShow(true);
    }
  }, []);

  useEffect(() => {
    let options = [];
    if (orderData) {
      options = orderData?.orderItem?.map((el) => {
        return {
          label: el?.product?.name,
          value: el?.variant_id,
          orderItemId: el?._id,
        };
      });
      setProductsOptions(options);
    }
    api.getAllProductOptions().then((res) => {
      let new_options = res.data.data?.map((el) => {
        return {
          label: el?.name,
          value: el?.name,
          productId: el?._id,
        };
      });
      setNewProductsOptions(new_options);
    });
  }, [orderData]);

  useEffect(() => {
    console.log('in use', selectedOrderItem);
    if (selectedOrderItem) {
      getVariantOptions(selectedOrderItem?.value);
    }
  }, [selectedOrderItem]);

  const getVariantOptions = (variantId) => {
    api
      .getVariants(variantId, { subscriptionOrder: true })
      .then((res) => {
        let options = [];
        options = res?.data?.data?.map((el) => {
          let label = Object.keys(el?.attributes).reduce((prev, curr) => {
            return prev + ` ${curr}(${el?.attributes[curr]})`;
          }, '');
          return {
            label,
            value: el?._id,
          };
        });
        setVariantOptions(options);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const changeVariant = () => {
    let payload = {
      id: orderData?._id,
      variant_id: selectedVariant?.value,
      order_item_id: selectedOrderItem?.orderItemId,
      subscriptionOrder: orderData?.subscription_id ? true : false,
    };
    api
      .updateVariantInOrder(payload)
      .then((res) => {
        toastSuccess(`Variant Updated Successfully`);
        dispatch(getOrderById(orderData?._id));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getProductVariant = (option) => {
    setSelectedNewProduct(option);
    api
      .getVariantOptions(option.productId)
      .then((res) => {
        let options = [];
        options = res?.data?.data?.map((el) => {
          let label = Object.keys(el?.attributes).reduce((prev, curr) => {
            return prev + ` ${curr}(${el?.attributes[curr]})`;
          }, '');
          return {
            label,
            value: el?._id,
          };
        });
        setVariantOptions(options);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const addOrderItem = () => {
    const payload = {
      order_id: orderData?._id,
      product_id: selectedNewProduct?.productId,
      variant_id: selectedVariant?.value,
      quantity: orderItemQuantity.value
    }
    api
      .addOrderItem(payload)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  const quantityOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
  ]

  return (
    <div>
      <div className="header_top " style={{ justifyContent: 'space-between' }}>
        <h3>{isUserAdmin() ? 'Update Order Details' : 'Update Variant'}</h3>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            toggleExpanded();
          }}
        >
          {isExpanded ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </div>
      </div>
      {isExpanded ? (
        <>
          <div className="mt-3 mx-2">
            {isUserAdmin() || isUserPharmacist() ? (
              <>
                <button
                  className="btn btn-primary me-2 order_btns p-3"
                  onClick={() => setAddressModalShow((prev) => !prev)}
                >
                  Update Address
                </button>
                {isUserAdmin() ? (
                  <>
                     <button
                       className="btn btn-primary order_btns p-3"
                       onClick={() => setVariantSectionShow((prev) => !prev)}
                     >
                       Update Variant
                     </button>
                      <button
                      className="btn btn-primary me-2 order_btns p-3"
                      onClick={() => setAddOrderItemForm((prev) => !prev)}
                    >
                      Add Order Item
                    </button>
                 </>
                ) : null}
              </>
            ) : null}
          </div>
          {addressModalShow ? (
            <UpdateAddressModal
              orderData={orderData}
              show={addressModalShow}
              handleToggle={() => setAddressModalShow(false)}
            />
          ) : null}
          {variantSectionShow ? (
            <div className="mt-3 ms-2">
              <div className="d-flex align-items-end">
                <div
                  className="d-flex flex-column"
                  style={{
                    width: '30%',
                  }}
                >
                  <p className="form-label">Select Order Item</p>
                  <CustomDropdown
                    height="44px"
                    className="mx-2"
                    onChange={(value) => {
                      setSelectedOrderItem(value);
                    }}
                    border={'1 px solid #9aa5ad'}
                    options={productsOptions}
                  />
                  <p className="form-label">Select New Product</p>
                  <CustomDropdown
                    height="44px"
                    className="mx-2"
                    onChange={(value) => {
                      getProductVariant(value);
                    }}
                    border={'1 px solid #9aa5ad'}
                    options={newProductsOptions}
                  />
                </div>
                {selectedOrderItem ? (
                  <div
                    className="d-flex flex-column"
                    style={{
                      width: '30%',
                    }}
                  >
                    <p className="form-label">Select Variant</p>

                    <CustomDropdown
                      height="44px"
                      className="mx-2"
                      onChange={(value) => {
                        setSelectedVariant(value);
                      }}
                      border={'1 px solid #9aa5ad'}
                      options={variantOptions}
                    />
                  </div>
                ) : null}
                <div className="">
                  <Button disabled={!selectedVariant} onClick={changeVariant}>
                    Change
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {addOrderItemForm && 
            <div className="mt-3 ms-2">
              <div className="d-flex align-items-end">
                <div
                  className="d-flex flex-column"
                  style={{
                    width: '30%',
                  }}
                >
                  <p className="form-label">Select New Product</p>
                  <CustomDropdown
                    height="44px"
                    className="mx-2"
                    onChange={(value) => {
                      getProductVariant(value);
                    }}
                    border={'1 px solid #9aa5ad'}
                    options={newProductsOptions}
                  />
                  {selectedNewProduct ? (
                  <div
                    className="d-flex flex-column"
                    style={{
                      width: '100%',
                    }}
                  >
                    <p className="form-label">Select Variant</p>

                    <CustomDropdown
                      height="44px"
                      className="mx-2"
                      onChange={(value) => {
                        setSelectedVariant(value);
                      }}
                      border={'1 px solid #9aa5ad'}
                      options={variantOptions}
                    />
                    <p className="form-label">Quantity</p>
                    <CustomDropdown
                      height="44px"
                      className="mx-2"
                      onChange={(value) => {
                        setOrderItemQuantity(value);
                      }}
                      border={'1 px solid #9aa5ad'}
                      options={quantityOptions}
                      value={orderItemQuantity}
                    />
                    <div className="m-2">
                      <Button disabled={!selectedVariant} onClick={addOrderItem}>
                        Add
                      </Button>
                    </div>
                  </div>
                ) : null}
                </div>
              </div>
            </div>
          }
        </>
      ) : null}
    </div>
  );
};

export default UpdateOrderDetails;
